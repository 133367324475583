import React, { useState, useEffect, useRef, useCallback } from 'react'
import Swiper from 'react-id-swiper'
import { Motion, spring } from 'react-motion'
import get from 'lodash.get'
import useMedia from 'use-media'
import useIsInViewport from 'use-is-in-viewport'
import { BREAKPOINT_LARGE } from '../../../utils/constants/responsive'
import SliderArrow from './SliderArrow'
import 'swiper/swiper.scss'
import './style.scss'

const Slider = ({ data }) => {
  const blockRef = useRef(null)
  const [swiper, setSwiper] = useState(null)
  const [isInViewport, wrappedTargetRef] = useIsInViewport({ target: blockRef })
  const isWide = useMedia({ minWidth: `${BREAKPOINT_LARGE}px` })
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 })
  const [isHovering, setIsHovering] = useState(false)

  const onPrevClick = useCallback(() => {
    if (swiper !== null) {
      swiper.slidePrev(0)
    }
  }, [swiper])

  const onNextClick = useCallback(() => {
    if (swiper !== null) {
      swiper.slideNext(0)
    }
  }, [swiper])

  const handleMouseMove = e => {
    const clientRect = blockRef.current.getBoundingClientRect()
    setCursorPos({
      x: e.clientX - clientRect.x,
      y: e.clientY - clientRect.y,
    })
  }

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  const handleNavClick = e => {
    if (e.clientX >= window.innerWidth / 2) {
      onNextClick()
    } else {
      onPrevClick()
    }
  }

  const handleKeyUp = useCallback(
    e => {
      if (isInViewport) {
        if (e.key === 'ArrowRight') {
          onNextClick()
        } else if (e.key === 'ArrowLeft') {
          onPrevClick()
        }
      }
    },
    [isInViewport, onNextClick, onPrevClick]
  )

  const params = {
    effect: 'fade',
    spaceBetween: 30,
    loop: true,
  }

  const images = get(data, 'fields', null)

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyUp])

  useEffect(() => {
    // Set the default cursor position to the middle of slider
    setCursorPos({
      x: blockRef.current.clientWidth / 2,
      y: blockRef.current.clientHeight / 2,
    })
  }, [blockRef])

  return (
    <div ref={wrappedTargetRef} className="block-slider">
      <Swiper getSwiper={setSwiper} {...params}>
        {images.map(slide => {
          const url = get(slide, 'image.url', null)
          const alt = get(slide, 'image.alt', null)

          return (
            <div key={url} className="slide">
              <img src={url} alt={alt} />
            </div>
          )
        })}
      </Swiper>

      {isWide && blockRef.current ? (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          onClick={handleNavClick}
          className="slider__nav slider__nav--desktop"
        >
          <Motion
            defaultStyle={{
              x: 0,
              y: 0,
              rotate: 0,
              opacity: 0,
            }}
            style={{
              x: spring(cursorPos.x - 50),
              y: spring(cursorPos.y),
              rotate: cursorPos.x > blockRef.current.clientWidth / 2 ? 0 : 180,
              opacity: spring(isHovering ? 1 : 0),
            }}
          >
            {style => (
              <div
                className="nav__cursor"
                style={{
                  left: style.x,
                  top: style.y,
                  transform: `rotate(${style.rotate}deg)`,
                  opacity: style.opacity,
                }}
              >
                <SliderArrow />
              </div>
            )}
          </Motion>
        </div>
      ) : (
        <div className="slider__nav slider__nav--mobile">
          <button onClick={onPrevClick}>
            <SliderArrow />
          </button>
          <button onClick={onNextClick}>
            <SliderArrow />
          </button>
        </div>
      )}
    </div>
  )
}

export default Slider
