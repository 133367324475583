import React from 'react'
import get from 'lodash.get'
import BlockSlider from './BlockSlider'
import BlockText from './BlockText'
import BlockSubtitle from './BlockSubtitle'
import BlockImage from './BlockImage'
import BlockVideo from './BlockVideo'
import RelatedContent from './RelatedContent'
import BlockTextAndImage from './BlockTextAndImage'
import './style.scss'

const SingleArticle = ({ data: article }) => {
  if (!article) {
    return null
  }

  const title = get(article, 'title[0].text', null)
  const subtitle = get(article, 'subtitle[0].text', null)
  const body = get(article, 'body', null)
  const hasContent = !!body && !!body.length

  const blockTypes = hasContent ? body.map(block => block.type) : []
  const hasTextAndImageBlock = blockTypes.includes('text_and_image')

  let classes = ['article']
  if (hasTextAndImageBlock) {
    classes.push('fullwidth')
  }

  return (
    <div className={classes.join(' ')}>
      <div className="container article__content">
        <h2 className="content__title">{title}</h2>
        {subtitle && (
          <h3 className="content__subtitle ft-subtitle">{subtitle}</h3>
        )}

        {hasContent && (
          <div className="content__body">
            {body.map((block, index) => (
              <div key={`${block.type}-${index}`}>
                {block.type === 'text' && <BlockText data={block} />}
                {block.type === 'text_and_image' && (
                  <BlockTextAndImage data={block} />
                )}
                {block.type === 'subtitle' && <BlockSubtitle data={block} />}
                {block.type === 'slider' && <BlockSlider data={block} />}
                {block.type === 'image' && <BlockImage data={block} />}
                {block.type === 'youtube' && <BlockVideo data={block} />}
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <RelatedContent article={article} />
      </div>
    </div>
  )
}

export default SingleArticle
