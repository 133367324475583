import React from 'react'
import get from 'lodash.get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'
import './style.scss'
import BlockImage from '../BlockImage'
import BlockSlider from '../BlockSlider'

const BlockTextAndImage = ({ data }) => {
  const fields = get(data, 'fields', [])
  const images = fields.filter(field => !!field.image)

  return (
    <div className="block-text-and-image">
      <div className="texts">
        {fields.map((block, index) => {
          const text = get(block, 'text', null)
          const subtitle = get(block, 'subtitle1', null)

          return (
            (!!subtitle || !!text) && (
              <div className="items">
                {!!subtitle && (
                  <div key={`${index}-subtitle`} className="item ft-subtitle">
                    {RichText.render(subtitle, linkResolver)}
                  </div>
                )}
                {!!text && (
                  <div key={`${index}-text`} className="item item-text">
                    {RichText.render(text, linkResolver)}
                  </div>
                )}
              </div>
            )
          )
        })}
      </div>

      {images.length > 1 ? (
        <BlockSlider data={data}></BlockSlider>
      ) : (
        <BlockImage data={{ primary: images[0] }} />
      )}
    </div>
  )
}

export default BlockTextAndImage
