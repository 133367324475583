import React from 'react'
import get from 'lodash.get'
import { getLocale } from '../../../utils/location'
import './style.scss'

const RelatedArticle = ({ article }) => {
  const locale = getLocale(article._meta.lang)
  const articleUrl = `/${locale}/article/${article._meta.uid}`
  const thumbUrl = get(article, 'thumbnail.url', null)
  const thumbAlt = get(article, 'thumbnail.alt', '')
  const body = get(article, 'body', null)
  const title = get(article, 'title[0].text', null)
  let subtitle = get(article, 'subtitle[0].text', null)

  if (!!body && Array.isArray(body)) {
    const subtitleBlock = body.find(i => i.type === 'subtitle')

    if (
      !!subtitleBlock &&
      !!subtitleBlock.primary &&
      !!subtitleBlock.primary.text &&
      !!subtitleBlock.primary.text[0] &&
      !!subtitleBlock.primary.text[0].text
    ) {
      subtitle = subtitleBlock.primary.text[0].text
    }
  }

  return (
    <div className="related-article">
      <a href={articleUrl}>
        {thumbUrl && (
          <div className="thumbnail">
            <img src={thumbUrl} alt={thumbAlt} />
          </div>
        )}
        <b className="ft-subtitle">{title}</b>
        {subtitle && <p>{subtitle}</p>}
      </a>
    </div>
  )
}

const BlockRelatedArticles = ({ langKey, data: articles }) => {
  if (!articles.length) {
    return null
  }

  return (
    <div className="related-articles">
      <div className="related__header ft-subtitle">Related content</div>
      <div className="article__related-articles">
        <div className="articles__list">
          {articles.map(
            article =>
              !!article &&
              article._meta.uid && (
                <RelatedArticle key={article._meta.uid} article={article} />
              )
          )}
        </div>
      </div>
    </div>
  )
}

export default BlockRelatedArticles
