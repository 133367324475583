import React from 'react'

const SliderArrow = () => {
  return (
    <svg viewBox="0 0 147 90">
      <path
        d="M146.572 45.024l-44.548 44.548-10.607-10.607 26.443-26.442H.524v-15H117.86l-26.443-26.44L102.024.476l44.548 44.548z"
        fill="#F73C02"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SliderArrow
