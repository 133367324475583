import React, { useRef, useState, useEffect, useCallback } from 'react'
import get from 'lodash.get'
import useMedia from 'use-media'
import { BREAKPOINT_LARGE } from '../../../utils/constants/responsive'
import useWindowSize from '../../../hooks/useWindowSize'
import './style.scss'

const BlockImage = ({ data }) => {
  const blockRef = useRef(null)
  const imageRef = useRef(null)
  const wSize = useWindowSize()
  const isWide = useMedia({ minWidth: `${BREAKPOINT_LARGE}px` })
  const [dimension, setDimension] = useState({ width: 0, height: 0 })
  const url = get(data, 'primary.image.url', null)
  const alt = get(data, 'primary.image.alt', '')

  const MULTIPLICATOR = isWide ? 0.7 : 0.85

  const resizeImage = useCallback(
    (srcWidth, srcHeight) => {
      const maxWidth = blockRef.current.clientWidth
      const maxHeight = wSize.height * MULTIPLICATOR

      const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

      setDimension({
        width: srcWidth * ratio,
        height: srcHeight * ratio,
      })
    },
    [wSize.height, MULTIPLICATOR]
  )

  const onLoad = ev =>
    resizeImage(ev.target.naturalWidth, ev.target.naturalHeight)

  useEffect(() => {
    if (!!imageRef && !!imageRef.current) {
      resizeImage(imageRef.current.naturalWidth, imageRef.current.naturalHeight)
    }
  }, [wSize, imageRef, resizeImage])

  if (!url) {
    return null
  }

  return (
    <div ref={blockRef} className="block-image">
      <img
        ref={imageRef}
        src={url}
        alt={alt}
        style={{
          width: `${dimension.width}px`,
          height: `${dimension.height}px`,
        }}
        onLoad={onLoad}
      />
    </div>
  )
}

export default BlockImage
