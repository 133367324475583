import get from 'lodash.get'

export const getRelatedItemsFromArticle = article => {
  if (!article) {
    return []
  }

  const body = get(article, 'body', [])

  if (!body) {
    return []
  }

  const relatedItem = body.find(i => i.type === 'related_articles')

  if (
    !relatedItem ||
    !relatedItem.fields ||
    (!!relatedItem.fields && relatedItem.fields.length === 0)
  ) {
    return []
  }

  return relatedItem.fields.map(i => i.related_article)
}
