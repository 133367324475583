import React from 'react'
import { getRelatedItemsFromArticle } from '../../../utils/relatedContent'
import BlockRelatedArticles from '../BlockRelatedArticles'

class RelatedContent extends React.Component {
  render() {
    const { article } = this.props
    const relatedArticles = getRelatedItemsFromArticle(article)

    return <BlockRelatedArticles data={relatedArticles} />
  }
}

export default RelatedContent
