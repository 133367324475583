import React from 'react'
import get from 'lodash.get'
import YouTube from 'react-youtube'
import getVideoId from 'get-video-id'

import './styles.scss'

const BlockVideo = ({ data }) => {
  const url = get(data, 'primary.url', null)
  const iframe = !!url && !!url.html ? url.html : null

  if (!iframe) {
    return null
  }

  return (
    <div className="block-video" dangerouslySetInnerHTML={{ __html: iframe }} />
  )
}

export default BlockVideo
