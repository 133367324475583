import React from 'react'
import get from 'lodash.get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'
import './style.scss'

const BlockText = ({ data }) => {
  return (
    <div className="block-text ft-paragraphs">
      {data.fields.map((field, index) => {
        const p = get(field, 'text', null)

        return <div key={index}>{RichText.render(p, linkResolver)}</div>
      })}
    </div>
  )
}

export default BlockText
