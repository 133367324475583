import React from 'react'
import get from 'lodash.get'
import './style.scss'

const BlockSubtitle = ({ data }) => {
  const title = get(data, 'primary.text[0].text', null)

  if (!title) {
    return null
  }

  return <div className="block-subtitle ft-subtitle">{title}</div>
}

export default BlockSubtitle
