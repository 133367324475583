import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import get from 'lodash.get'
import LayoutFR from '../../layouts/fr'
import SEO from '../../components/seo'
import SingleArticle from '../../components/SingleArticle'

const Article = ({ pageContext, location, data }) => {
  const article = get(data, 'prismic.article')
  if (!article) {
    return null
  }

  return (
    <LayoutFR location={location} isDark>
      <Location>
        {({ location }) => <SEO title="Article" url={location.pathname} />}
      </Location>
      <div className="page">
        <SingleArticle data={article} />
      </div>
    </LayoutFR>
  )
}

export const query = graphql`
  query ArticleQueryEN($uid: String!) {
    prismic {
      article(uid: $uid, lang: "en-us") {
        _meta {
          id
          uid
          lang
        }
        title
        title_home
        subtitle
        date
        thumbnail
        body {
          ... on PRISMIC_ArticleBodySlider {
            type
            label
            fields {
              image
            }
          }
          ... on PRISMIC_ArticleBodyText {
            type
            label
            fields {
              text
            }
          }
          ... on PRISMIC_ArticleBodyText_and_image {
            type
            label
            fields {
              text
              subtitle1
              image
            }
          }
          ... on PRISMIC_ArticleBodyRelated_articles {
            type
            label
            fields {
              related_article {
                ... on PRISMIC_Article {
                  title
                  subtitle
                  thumbnail
                  _meta {
                    uid
                    id
                    lang
                  }
                  body {
                    ... on PRISMIC_ArticleBodySubtitle {
                      type
                      label
                      primary {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PRISMIC_ArticleBodyImage {
            type
            label
            primary {
              image
            }
          }
          ... on PRISMIC_ArticleBodyYoutube {
            type
            label
            primary {
              url
            }
          }
          ... on PRISMIC_ArticleBodySubtitle {
            type
            label
            primary {
              text
            }
          }
        }
      }
      featuredArticles: allHomepages {
        edges {
          node {
            featured_articles {
              article {
                ... on PRISMIC_Article {
                  _meta {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Article
